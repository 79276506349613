import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const SITE_METADATA_QUERY = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

const SEO: React.FC = () => {
  const {
    site: {
      siteMetadata: { title, description },
    },
  } = useStaticQuery(SITE_METADATA_QUERY);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export { SEO };
