import { useMemo } from 'react';
import { Role } from '../utils/role';
import { getMsalInstance } from '../utils/get-msal-instance';

const useRole = () => {
  const msalInstance = getMsalInstance();

  const isAdmin = useMemo(() => {
    const account = msalInstance.getActiveAccount();
    const roles = account?.idTokenClaims?.roles;
    return !roles || roles.length === 0 || roles.includes(Role.ACCESS_ADMIN);
  }, [msalInstance]);

  const isTester = useMemo(() => {
    const account = msalInstance.getActiveAccount();
    const roles = account?.idTokenClaims?.roles;
    return roles && roles.length !== 0 && !roles.includes(Role.ACCESS_ADMIN) && roles.includes(Role.ACCESS_TEST);
  }, [msalInstance]);

  const isSearcher = useMemo(() => {
    const account = msalInstance.getActiveAccount();
    const roles = account?.idTokenClaims?.roles;
    return roles && roles.length !== 0 && roles.includes(Role.ACCESS_SEARCH);
  }, [msalInstance]);

  const isReadOnly = useMemo(() => {
    const account = msalInstance.getActiveAccount();
    const roles = account?.idTokenClaims?.roles;
    return roles && roles.length !== 0 && roles.includes(Role.ACCESS_READONLY);
  }, [msalInstance]);

  const isCommenter = useMemo(() => {
    const account = msalInstance.getActiveAccount();
    const roles = account?.idTokenClaims?.roles;
    return roles && roles.length !== 0 && roles.includes(Role.FEATURE_COMMENTS);
  }, [msalInstance]);

  const isReviewer = useMemo(() => {
    const account = msalInstance.getActiveAccount();
    const roles = account?.idTokenClaims?.roles;
    return roles && roles.length !== 0 && roles.includes(Role.FEATURE_COMMENTS);
  }, [msalInstance]);

  return { isAdmin, isTester, isSearcher, isReadOnly, isCommenter, isReviewer };
};

export default useRole;
