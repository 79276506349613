import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { copyRules, segmentSelectors } from '../../../store/segment';
import { trackEvent, util } from '../../../services';
import { ToolbarIconButton } from '../ToolbarIconButton/ToolbarIconButton';
import { AppBar, Toolbar, Snackbar, Slide } from '@mui/material';
import grey from '@mui/material/colors/grey';
import WrapTextIcon from '@mui/icons-material/WrapText';
import AutoSuggestIcon from '@mui/icons-material/Spellcheck';
import CloseIcon from '@mui/icons-material/Close';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import {
  settingSelectors,
  toggleSyntaxHighight,
  toggleWordWrap,
  closeRulesPanel,
  toggleAutoScroll,
  toggleAutoSuggest,
} from '../../../store/setting';
import { ToolbarToggleButton } from '../ToolbarToggleButton/ToolbarToggleButton';
import { ScrollIcon } from '../ScrollIcon/ScrollIcon';
import { ClipboardIcon } from '../ClipboardIcon/ClipboardIcon';
import { ExportRulesMenuButton } from '../ExportRulesMenuButton/ExportRulesMenuButton';
import { gridSelectors, snapBestPossibleRule } from '../../../store/rulesGrid';

function SlideTransition(props: any) {
  return <Slide {...props} direction="up" />;
}

const fixedAppBar = {
  position: 'fixed',
  backgroundColor: grey[200],
  zIndex: 999,
};

const toolbarStyle = {
  justifyContent: 'flex-end',
  overflow: 'hidden',
  marginLeft: '10px',
};

const RulesToolbar: React.FC = memo(() => {
  const hasRules = useSelector(segmentSelectors.hasRules);
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const syntaxHighlight = useSelector(settingSelectors.syntaxHighlight);
  const wordWrap = useSelector(settingSelectors.wordWrap);
  const autoSuggest = useSelector(settingSelectors.autoSuggest);
  const isFullScreen = useSelector(settingSelectors.rulePanelFullScreen);
  const autoScroll = useSelector(settingSelectors.autoScroll);
  const isSegmentPanelClosed = useSelector(settingSelectors.isSegmentPanelClosed);
  const panelWidth = useSelector(gridSelectors.rulesPanelWidth);

  const handleCopy = () => {
    dispatch(copyRules());
    setOpenSnackbar(true);
    trackEvent.copyToClipboard();
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleWordwrap = () => {
    dispatch(toggleWordWrap());
    trackEvent.wordwrapToggle(!wordWrap ? 1 : 0);
  };
  const handleAutoSuggest = () => {
    dispatch(toggleAutoSuggest());
    //trackEvent.wordwrapToggle(!wordWrap ? 1 : 0);
  };

  const handleAutoScrollToggle = () => {
    dispatch(toggleAutoScroll());
    trackEvent.autoScrollToggle(!autoScroll ? 1 : 0);
    if (!autoScroll) {
      dispatch(snapBestPossibleRule());
    }
  };

  const handleSyntaxHighlight = () => {
    dispatch(toggleSyntaxHighight());
    trackEvent.syntaxHighlightToggle(!syntaxHighlight ? 1 : 0);
  };

  const handleCollapse = () => {
    dispatch(closeRulesPanel());
    trackEvent.rulePanelOpened();
    util.fireSplitPaneResizeEvent(Math.random());
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Rules copied to clipboard!"
        TransitionComponent={SlideTransition}
      />

      <AppBar position="static" color="inherit" sx={fixedAppBar} style={{ width: panelWidth }}>
        <Toolbar variant="dense" disableGutters={true} sx={toolbarStyle}>
          {!isSegmentPanelClosed && (
            <ToolbarToggleButton
              value="auto scroll"
              selected={autoScroll}
              onClick={handleAutoScrollToggle}
              title={autoScroll ? 'Auto align off' : 'Auto align on'}
            >
              <ScrollIcon />
            </ToolbarToggleButton>
          )}
          <ToolbarToggleButton
            value="wrap"
            selected={wordWrap}
            onChange={handleWordwrap}
            title={wordWrap ? 'Word wrap off' : 'Word wrap on'}
          >
            <WrapTextIcon fontSize="small" />
          </ToolbarToggleButton>

          <ToolbarToggleButton
            value="autoSuggest"
            selected={autoSuggest}
            onChange={handleAutoSuggest}
            title={autoSuggest ? 'auto suggest off' : 'auto suggest on'}
          >
            <AutoSuggestIcon fontSize="small" />
          </ToolbarToggleButton>
          <ToolbarToggleButton
            value="syntax"
            selected={syntaxHighlight}
            onChange={handleSyntaxHighlight}
            title={syntaxHighlight ? 'Syntax highlight off' : 'Syntax highlight on'}
          >
            <FormatColorTextIcon fontSize="small" />
          </ToolbarToggleButton>

          <ToolbarIconButton
            aria-label="copy rules to clipboard"
            onClick={handleCopy}
            disabled={!hasRules}
            title="Copy to clipboard"
          >
            <ClipboardIcon />
          </ToolbarIconButton>

          <ExportRulesMenuButton disabled={!hasRules} />

          <ToolbarIconButton
            aria-label="Close rule panel"
            onClick={handleCollapse}
            title="Close"
            disabled={isFullScreen}
          >
            <CloseIcon fontSize="small" />
          </ToolbarIconButton>
        </Toolbar>
      </AppBar>
    </>
  );
});

export { RulesToolbar };
