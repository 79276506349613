import React, { useState, FormEvent, ChangeEvent, FocusEvent, KeyboardEvent, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    fontWeight: 600,
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
  },
  labelWrapper: (props: any) => ({
    position: 'relative',
    display: 'inline-block',
    boxShadow: '0px 0px 2px 2px rgba(97,97,97,0)',
    border: `1px solid rgba(97,97,97,0)`,
    transition: 'all 0.5s ease-in-out',
    '&:hover': props.disabled
      ? {}
      : {
          boxShadow: '0px 0px 2px 2px rgba(97,97,97,0.5)',
          border: `1px solid rgba(97,97,97,0.5)`,
        },
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100px',
    flexGrow: 1,
  }),
  titlePlaceholder: {
    color: grey[600],
    fontStyle: 'italic',
  },
  input: {
    padding: '0 3px',
    fontWeight: 600,
    width: '100%',
    outlineOffset: '0px',
    outline: 'none',
    border: `1px solid rgba(97,97,97,0.5)`,
    boxShadow: '0px 0px 2px 2px rgba(97,97,97,0.5)',
    flexGrow: 1,
  },
  form: {
    height: '100%',
    width: '100%',
    display: 'flex',
    margin: '3px 0',
  },
});

type EditableTitleProps = {
  titlePlaceholder: string;
  inputPlaceholder: string;
  value?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

const EditableTitle: React.FC<EditableTitleProps> = ({
  value,
  inputPlaceholder,
  titlePlaceholder,
  onChange,
  disabled = false,
}) => {
  const classes = useStyles({ disabled });
  const [isEditing, setEditing] = useState(false);
  const [title, setTitle] = useState((value && value.trim()) || '');

  useEffect(() => {
    setTitle(value || '');
  }, [value]);

  const handleSubmit = (event: FormEvent<any>) => {
    event.preventDefault();
    finishEditing();
  };

  const finishEditing = () => {
    setEditing(false);
  };

  const handleChange = (event: ChangeEvent<any>) => {
    setTitle(event.target.value);
    onChange(event.target.value);
  };

  const handleFocus = (event: FocusEvent<any>) => event.target.select();
  const handleKeyDown = (event: KeyboardEvent<any>) => event.keyCode === 27 && finishEditing(); //Esc key

  const inputField = (
    <form onSubmit={handleSubmit} className={classes.form}>
      <input
        type="text"
        value={title}
        onBlur={finishEditing}
        placeholder={inputPlaceholder}
        autoFocus
        className={classes.input}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
      />
    </form>
  );

  const label = (
    <h2 onClick={() => setEditing(true)} className={classes.labelWrapper} title={disabled ? '' : 'Click to edit'}>
      {title ? title : <span className={classes.titlePlaceholder}>{titlePlaceholder}</span>}
    </h2>
  );

  return <div className={classes.root}>{isEditing && !disabled ? inputField : label}</div>;
};

export { EditableTitle };
