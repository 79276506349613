import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import theme from '../../../gatsby-theme-material-ui-top-layout/theme';

const root = {
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: theme.palette.grey[200],
  opacity: 0.8,
};

const spinner = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  marginLeft: '-50px',
};

const Spinner: React.FC = () => {
  return (
    <Box sx={root}>
      <CircularProgress color="secondary" sx={spinner} size="5rem" />
    </Box>
  );
};

export { Spinner };
