import React from 'react';
import { makeStyles } from '@mui/styles';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import theme from '../../../gatsby-theme-material-ui-top-layout/theme';

const useStyles = makeStyles({
  rootLabel: {
    padding: theme.spacing(1),
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
});

const uploadImg = {
  fontSize: '4rem',
  width: 'auto',
  marginTop: '20px',
};

interface InputFilePorp {
  onUpload: (file: File) => void;
}

const FileInput: React.FC<InputFilePorp> = ({ onUpload }) => {
  const classes = useStyles();

  return (
    <label className={classes.rootLabel}>
      <input
        type="file"
        name="files[]"
        id="file"
        hidden
        onChange={(e) => onUpload(e.target.files![0])}
        accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      />
      Browse for files
      <CloudUploadOutlinedIcon sx={uploadImg} />
    </label>
  );
};

export { FileInput };
