export function getCookie(name: string) {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length === 2) return parts?.pop()?.split(';').shift();
  return '';
}

export function setCookie(key: string, value: string) {
  document.cookie = `${key}=${value}; path=/; domain=localhost;`;
}
