import { AccountInfo } from '@azure/msal-browser';

export enum Role {
  ACCESS_USER = 'Access.User',
  ACCESS_ADMIN = 'Access.Admin',
  ACCESS_SEARCH = 'Access.Search',
  ACCESS_TEST = 'Access.Test',
  ACCESS_READONLY = 'Access.ReadOnly',
  ACCESS_REVIEWER = 'Access.Reviewer',
  FEATURE_COMMENTS = 'Feature.Comments',
  LABEL_BIOSECURITY = 'Label.Biosecurity',
  LABEL_EGC = 'Label.EGC',
  ALL_LABELS = 'Label.*',
}

export const hasRoles = (account: AccountInfo, roles: Role[]) =>
  account.idTokenClaims?.roles?.every((role) => roles.includes(role as Role));
