import React from 'react';
import { makeStyles } from '@mui/styles';
import { Header, SEO } from '../ui';
import { GlobalStyle } from '../../styles/global.css';
import { Box, Typography } from '@mui/material';
import { getMsalInstance } from '../../utils/get-msal-instance';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { SnackbarProvider } from 'notistack';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  main: {
    flexGrow: 2,
  },
});

const AppLayout: React.FC = ({ children }) => {
  const classes = useStyles();
  const msalInstance = getMsalInstance();

  return (
    <MsalProvider instance={msalInstance}>
      <GlobalStyle />
      <SEO />
      <SnackbarProvider maxSnack={3}>
        <div className={classes.root}>
          <Header />
          <AuthenticatedTemplate>
            <main className={classes.main}>{children}</main>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Box height="100%" display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h2">Please login to use this app</Typography>
            </Box>
          </UnauthenticatedTemplate>
        </div>
      </SnackbarProvider>
    </MsalProvider>
  );
};

export default AppLayout;
