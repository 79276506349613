import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { grey } from '@mui/material/colors';

const GlobalStyle = createGlobalStyle`
${normalize}
html,
body,
#___gatsby {
  height: 100%;
  font-size: 1rem;
}

body {
  margin: 0px;
}

div[role='group'][tabindex], #gatsby-focus-wrapper {
  height: 100%
}

.lt-table-row {
  display: flex;
  justify-content: space-between;
}

.lt-table-row > * {
  padding: 5px;
  flex-basis: 33%;
  flex-grow: 1
}

.lt-table-header-group {
  font-weight: 600
}

.lt-list-item {
  padding-left: 5rem
}

.lt-list-item:before {
  content: "● ";
  display: inline-block;
  width: 25px;
}

h1, h2, h3, h4, h5 {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  vertical-align: baseline;
  font-weight: 600;
}

h6{
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  vertical-align: baseline;
  font-weight: 500;
}


.CodeMirror {
  font-size: 1rem;
  background: none;
  font-family: Roboto Mono,Arial,sans-serif;
  height: 100%;
}

.CodeMirror-linenumbers {
  background-color: ${grey[300]};
}

.CodeMirror-linenumber {
  color: ${grey[600]};
  font-weight: 500
}

.CodeMirror-focused .cm-matchhighlight {
  background-color: rgba(177, 255, 240, 0.25)
}

.CodeMirror-placeholder {
  color: ${grey[700]} !important;
  font-style: italic
}
.CodeMirror-cursor {
  border-left: 2px solid black;
}
.highlight {
  color: red;
}
.underline-line {
  text-decoration: underline;
  color: red;
}
.error-marker {
  transform: translate(-20px);
}
.error-tooltip {
  position: absolute;
  background-color: #f44336;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 10;
  white-space: nowrap;
  visibility: hidden;
}
.error-marker:hover .error-tooltip {
  visibility: visible;
}

`;

export { GlobalStyle };
