import { BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: process.env.MSAL_CLIENT_ID || '',
    authority: process.env.MSAL_AUTHORITY || '',
    redirectUri: process.env.MSAL_CALLBACK,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export const getMsalInstance = () => {
  return msalInstance;
};
